import React from "react";
import { Box, Typography, Divider } from "@material-ui/core";

const Footer = () => {
  return (
    <Box>
      <Divider className="borderBox" />
      <Box className="displayCenter">
        <Typography
          variant="body2"
          style={{ color: "rgba(255, 255, 255, 0.6)" }}
        >
          Copyright©2024. Created with love by{" "}
          <span className="gradientfirexText" style={{ fontSize: "18px" }}>
            FIEREX
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
